import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import React , { useState , useEffect } from 'react'

//Header
const Header = () => {

    // Coming Soon
    const handleClick = (event) => {
        event.preventDefault();
        alert('Coming Soon');
    };
    
    // Toggle Launch
    const [isOpen, setIsOpen] = useState(false);
    const handleDrawer = () => {
        setIsOpen(!isOpen);
    };
    useEffect(() => {
        const handleEscKeyPress = (e) => {
            if (e.keyCode === 27 && isOpen) {setIsOpen(false);}
        };

        if (isOpen) {
            document.body.style.setProperty("overflow", "hidden");
        } else {
            document.body.style.removeProperty("overflow");
        }

        document.addEventListener("keydown", handleEscKeyPress);

        return () => {
            document.removeEventListener("keydown", handleEscKeyPress);
        };
    }, [isOpen]);


    return (<>
        <header id='header'>
            <div className='container'>
                <h1><a href="#firstPage"><span className='sound_only'>bitboard</span></a></h1>

                <nav id="gnb" className="gnb">
                    <ul>
                        {/* <li onClick={() => setActiveIndex(1)}>Platform</li> */}
                        {/* <li onClick={() => setActiveIndex(2)}>Solutions</li> */}
                        {/* <li onClick={() => setActiveIndex(3)}>Wallet</li> */}
                        {/* <li onClick={() => setActiveIndex(4)}>Ecosystem</li> */}
                        {/* <li onClick={() => setActiveIndex(5)}>Technical</li> */}
                        {/* <li onClick={() => setActiveIndex(6)}>Use Cases</li> */}
                    </ul>
                </nav>
                
                <div className='right'>
                    <a href='https://ktoss-bb-organization.gitbook.io/bitboard-whitepaper' target='blank' className="btn-gitbook"><span>Gitbook</span></a>
                    <button type="button" className="btn-launch" onClick={handleDrawer}><span>Launch App</span></button>
                </div>
            </div>
        </header>

        <div className={`launch-container ${isOpen ? "open" : ""}`}>
            <div className='launch-head'>
                <div className='title'>bitboard</div>
                <button type='button' className='btn-close' onClick={() => setIsOpen(false)}><span className='sound_only'>close</span></button>
            </div>
            <div className='launch-content'>
                <nav className="snb">
                    <ul>
                        <li><a href="#secondPage" onClick={() => setIsOpen(false)}><span>Platform</span></a></li>
                        <li><a href="#thirdPage" onClick={() => setIsOpen(false)}><span>Solutions</span></a></li>
                        <li><a href="#fourthPage" onClick={() => setIsOpen(false)}><span>Wallet</span></a></li>
                        <li><a href="#fifthPage" onClick={() => setIsOpen(false)}><span>Ecosystem</span></a></li>
                        <li><a href="#sixthPage" onClick={() => setIsOpen(false)}><span>Technical</span></a></li>
                        <li><a href="#seventhPage" onClick={() => setIsOpen(false)}><span>Use Cases</span></a></li>
                    </ul>
                </nav>
                <div className='accordion-container'>
                    <Accordion defaultExpanded>
                        <AccordionSummary>
                            <div className='title'>Bitboard Platform</div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='content'>
                                <a href='https://w.bitboard.in/' target='blank' className='link-platform bbtoken'><p>Bitboard Token <br/><small>Bitboard Chart</small></p></a>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded>
                        <AccordionSummary>
                            <div className='title'>Tnet</div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='content'>
                                <a href='https://tnetscan.in/' target='blank' className='link-platform tnetscan'><p>tnetscan.in <br/></p></a>
                                <a href='https://tnetw.in/ko/auth' target='blank' className='link-platform tnetwallet'><p>Tnet Wallet <br/></p></a>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
        </div>
    </>)
}

export default Header